import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://study-room-me.web.app"
      }}>{`Study Room @ UTD`}</a>{` is a web app that allows students to find study rooms on campus. It is built with Node.js, Express, and Firebase. It also uses Material UI for the front end. The app is hosted on Firebase's hosting. Coursebook is scraped for course information, which is then saved to the backend and exposed through an API. The code is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/class-search"
      }}>{`on GitHub`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/study-room-utd/header.png",
        "alt": null
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/images/study-room-utd/sc1.png",
        "alt": null
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/images/study-room-utd/sc2.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      